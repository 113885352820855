<template>
  <a-row class="content-fix" type="flex">
    <a-col class="height100 flex-1">
      <a-row class="btn-row">
        <button
          class="tab-btn"
          :class="{ active: tab === 'content' }"
          @click="tab = 'content'"
        >
          <a-icon type="project" />Содержимое
        </button>
      </a-row>

      <div v-show="tab === 'content'" class="main-tabs" type="card">
        <!-- Language Selection -->
        <a-row align="middle" class="flex-baseline sm-mb">
          <a-col :span="6" style="text-align: right; padding-right: 8px"
            >Язык:</a-col
          >
          <a-col :span="12">
            <a-row align="middle" class="flex-baseline flex-wrap">
              <lang-tabs v-model="langTab" class="mb-1x" />
            </a-row>
          </a-col>
        </a-row>

        <!-- File Upload -->
        <a-row align="middle" class="flex-baseline">
          <a-col :span="6" style="text-align: right; padding-right: 8px">
            {{ $t("projectRegistry") }}
          </a-col>
          <a-col :span="12">
            <!-- Upload Button -->
            <a-button type="primary" @click="$refs.documentRef.click()">
              <a-icon type="upload" />Выберите файл
            </a-button>
            <input
              ref="documentRef"
              accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf"
              hidden
              type="file"
              @change="handleDocumentInput"
            />

            <!-- Show Selected File Name -->
            <div v-if="fileNames[langTab]" style="margin-top: 10px">
              <strong>Выбранный файл:</strong> {{ fileNames[langTab] }}
            </div>

            <!-- Show Existing File Link -->
            <div v-if="form.file[langTab]" style="margin-top: 10px">
              <a
                :href="form.file[langTab]"
                target="_blank"
                style="color: #1890ff"
              >
                <a-icon type="link" /> Открыть загруженный файл
              </a>
            </div>
          </a-col>
        </a-row>

        <!-- Buttons -->
        <a-row style="margin-top: 20px">
          <a-col :span="6" />
          <a-col :span="12">
            <a-row>
              <a-button style="margin-right: 10px" @click="$router.go(-1)">
                <a-icon type="close" /> Отмена
              </a-button>
              <a-button type="primary" @click="submitData">
                <a-icon type="check" /> Сохранить
              </a-button>
            </a-row>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>

<script>
export default {
  data() {
    return {
      form: {
        file: {
          ru: null,
          uz: null,
          oz: null,
          en: null,
          qr: null
        },
        created_at: "",
        updated_at: ""
      },
      fileNames: {
        ru: "",
        uz: "",
        oz: "",
        en: "",
        qr: ""
      },
      langTab: "oz",
      tab: "content"
    }
  },
  async mounted() {
    await this.fetchFileUrls()
  },
  methods: {
    async fetchFileUrls() {
      try {
        const { data } = await this.$api.get(
          "/admin/common/ppp-projects-registry/update/"
        )

        // Assign file URLs and extract file names
        Object.keys(this.form.file).forEach((lang) => {
          if (data[`file_${lang}`]) {
            this.form.file[lang] = data[`file_${lang}`]
            this.fileNames[lang] = data[`file_${lang}`].split("/").pop() // Extract file name
          }
        })

        // Assigning Read-Only Dates
        this.form.created_at = data.created_at || ""
        this.form.updated_at = data.updated_at || ""
      } catch (error) {
        console.error("Error fetching file data:", error)
      }
    },

    handleDocumentInput(event) {
      const file = event.target.files[0]
      if (file) {
        // Assign the selected file to the current language
        this.$set(this.form.file, this.langTab, file)

        // Update the file name for UI display
        this.$set(this.fileNames, this.langTab, file.name)

        // Reset file input to allow re-selection of the same file
        this.$refs.documentRef.value = ""
      }
    },

    async submitData() {
      try {
        const formData = new FormData()
        const allFilesUpdated = Object.values(this.form.file).every(
          (file) => file instanceof File
        )
        const someFileUpdated = Object.values(this.form.file).some(
          (file) => file instanceof File
        )

        if (!someFileUpdated) {
          this.$message.warning("No files have been uploaded for creation.")
          return
        }

        let response

        // Determine which files are updated and only send those
        const updatedFiles = {}
        Object.keys(this.form.file).forEach((lang) => {
          if (this.form.file[lang] instanceof File) {
            updatedFiles[lang] = this.form.file[lang]
          }
        })

        // If all files are being created (all are File objects), use PUT
        if (allFilesUpdated) {
          Object.keys(updatedFiles).forEach((lang) => {
            formData.append(`file_${lang}`, updatedFiles[lang])
          })

          response = await this.$api.put(
            "/admin/common/ppp-projects-registry/update", // Verify this endpoint for creating with PUT
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" }
            }
          )
        }
        // If only some files are being created, use PATCH
        if (someFileUpdated) {
          Object.keys(updatedFiles).forEach((lang) => {
            formData.append(`file_${lang}`, updatedFiles[lang])
          })

          response = await this.$api.patch(
            "/admin/common/ppp-projects-registry/update", // Verify this endpoint for creating with PATCH
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" }
            }
          )
        }

        // Safely access the data from the response
        const { data } = response
        if (data && data.fileUrls) {
          // Update the Vuex store or local state with the created data
          this.$store.commit("SET_FILE_DATA", {
            fileUrls: {
              ru: data.fileUrls.ru || "",
              uz: data.fileUrls.uz || "",
              oz: data.fileUrls.oz || "",
              en: data.fileUrls.en || "",
              qr: data.fileUrls.qr || ""
            },
            created_at: data.created_at || "",
            updated_at: data.updated_at || ""
          })
        } else {
          console.warn("API response does not contain expected data:", response)
          this.$message.warning(
            "Не удалось получить данные созданного реестра."
          )
        }

        this.$message.success("Новый реестр успешно создан")
        this.$router.push({ name: "publicPrivateRegisterServices" })
      } catch (e) {
        console.error("Error creating data:", e)
        this.$message.error("Ошибка при создании реестра")
      }
    }
  }
}
</script>
